import React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Title/Title"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import CardList from "../components/CardList/CardList"

import "normalize.css"
import "../assets/styles/global.scss"

const Home = () => {
  return (
    <div>
      <Layout>
        <Seo
          title="UI Designer & Developer"
          description="I'm Marcus Hägerstrand, a UI/UX Designer and developer with a passion for
            simplistic, user-friendly design and animations"
        />
        <Title />
        <SectionTitle
          title="Selected projects"
          text="I’ve worked on a variety of projects helping clients from different sectors to create beautiful and user-focused websites. This list contains a mix of previous client work and school projects."
        />
        <CardList />
        <SectionTitle
          title="About me"
          text={
            <>
              Put the user first. Sweat the little details. Fight for good UX. I
              am a Gothenburg-based frontend developer and UI/UX designer,
              interested in shaping engaging websites and mindful interactive
              experiences, whether by helping with code, design or both.
              <br />
              <br />
              Previously worked at{" "}
              <a className="link" href="https://www.grebban.com/">
                Grebban
              </a>{" "}
              ,where I help build beautiful, scalable and user-friendly
              e-commerce solutions for iconic brands
            </>
          }
        />
      </Layout>
    </div>
  )
}

export default Home
