import React from "react"
import { Fade } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"

import "./cardList.scss"

const Cards = [
  {
    id: 1,
    title: "Johaug",
    description:
      "Premium workout, hiking, and casual wear named after former Cross Country Skier Therese Johaug",
    link: "https://www.johaug.com/eu/en/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/johaug-thumbnail.avif"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["Gatsby", "Storyblok", "Centra", "Algolia", "AWS"],
    isWhite: true,
  },
  {
    id: 2,
    title: "Björn Borg",
    description:
      "A Swedish apparel fashion brand named after the former professional tennis player",
    link: "https://www.bjornborg.com/en/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/bjorn-borg-thumbnail.webp"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["Gatsby", "Storyblok", "Centra", "Algolia", "AWS"],
    isWhite: true,
  },
  {
    id: 3,
    title: "Dahlie",
    description:
      "Norweigian Sportswear with focus on innovation, function, and design. Established by skiing legend Bjørn Dæhlie",
    link: "https://www.dahlie.com/eu/en/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/dahlie-thumbnail.webp"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["Gatsby", "Storyblok", "Centra", "Algolia", "AWS"],
    isWhite: true,
  },
  {
    id: 4,
    title: "Sweet Protection",
    description:
      "Sweet Protection blends industry-leading innovation with the finest craftsmanship available to create the safest and most advanced helmets",
    link: "https://www.sweetprotection.com/eu/en/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/sp-thumbnail.webp"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["Gatsby", "Storyblok", "Centra", "Algolia", "AWS"],
    isWhite: true,
  },
  {
    id: 5,
    title: "Kari Traa",
    description:
      "Kari Traa sportswear designed for hiking, skiing and training",
    link: "https://www.karitraa.com/eu/en/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/kt-thumbnail.webp"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["Gatsby", "Storyblok", "Centra", "Algolia", "AWS"],
    isWhite: true,
  },
  {
    id: 6,
    title: "Byon",
    description:
      "Byon offers playful interior details to everyone who wants to create personal & happy homes",
    link: "https://www.byondesign.com/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/byon-thumbnail.png"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "E-commerce"],
    languages: ["React", "WordPress", "Centra"],
    isWhite: true,
  },
  {
    id: 7,
    title: "Yum Yum",
    description:
      "Developed a simple restaurant website powered by Contentful API",
    link: "https://github.com/marcusxyz/yumyum",
    buttonText: "View project on Github",
    image: (
      <StaticImage
        src="../../assets/images/06-yumyum-min.webp"
        alt="Restaurant during rush hour"
        layout="fullWidth"
        placeholder="blurred"
      />
    ),
    topics: ["School project", "Ramen shop"],
    languages: ["React", "Contentful", "Styled-components"],
    isWhite: true,
  },
  {
    id: 8,
    title: "Kiviks Musteri",
    description:
      "Help showcase a wide range of products for a beverage company",
    link: "https://www.kiviksmusteri.se/",
    buttonText: "View website",
    image: (
      <StaticImage
        src="../../assets/images/02-kiviks-thumb-min.webp"
        alt="A product display on Kiviks website with a blurred background"
        placeholder="blurred"
      />
    ),
    topics: ["Client work", "Cider mill"],
    languages: ["Web design", "Figma"],
    isWhite: true,
  },
]

const CardsListItem = props => {
  return (
    <div className="project-card">
      <a href={props.card.link} rel="external" target="blank">
        <div className={props.card.isWhite ? "content white" : "content"}>
          <div className="tags">
            <div className="topics bottom">
              <ul>
                {props.card.topics.map((topic, key) => {
                  return <li key={key}>{topic}</li>
                })}
              </ul>
            </div>
            <div className="languages top">
              <ul>
                {props.card.languages.map((language, key) => {
                  return (
                    <li className={props.card.isWhite ? "white" : ""} key={key}>
                      {language}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="button">
            <button>
              <div className="button-content">
                <svg
                  width="16"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#a)">
                    <path d="M0 7h16v2H0z" />
                    <path d="M7 16V0h2v16z" />
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h16v16H0z" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{props.card.buttonText}</span>
              </div>
            </button>
          </div>
          <div className="info">
            <h4>{props.card.title}</h4>
            <p>{props.card.description}</p>
          </div>
        </div>
        <div className="thumbnail">{props.card.image}</div>
      </a>
    </div>
  )
}

const CardList = () => {
  return (
    <section className="card-container">
      {Cards.map(card => {
        return <CardsListItem card={card} key={card.id} />
      })}
    </section>
  )
}

export default CardList
